<template>
  <div>
      <h2>Ploutus Staff</h2>
      <div class="list-holder">
          <div class="tile p-mb-5 p-mt-5 p-ml-5 p-mr-5"  v-for="{id, fname, lname, email, desg, number, pic} in emp" :key="id">
              <img :src="require(`../assets/staff/${pic}`)" class="pic" /><br>
              <div class="name">{{fname}} {{lname}}</div>
              <div class="desg">{{desg}}</div>
              <div class="email">{{email}}</div>
              <div class="number">{{number}}</div>
          </div>
      </div>
  </div>
</template>

<script>
import {useLoadEmp} from '@/fbDomain'

export default {
    setup(){
        const emp = useLoadEmp()
        return {emp}
    }
}
</script>

<style>
.list-holder{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tile{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    /* word-break: break-all; */
}
.name {
    font-size: 20px;
    font-weight: 600;
    color: #fab500;
}
.desg {
    font-size: 18px;
}
.pic{
    width: 180px;
}
</style>