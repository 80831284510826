<template>
  <div>
    <Header />
    <h2>About Ploutus</h2>
    <div  class="container text-justify">
      <p> Ploutus Holdings LLC is a dynamic and multifaceted international organization with a plethora of services and years of experience. We lay down strong foundations for a better tomorrow, providing profitable solutions to the toughest challenges. From Kenya to Sri Lanka, we have a long list of successfully completed projects. Ploutus Holdings LLC has many different subsidiaries associated with it, with projects including contracting services, telecommunications services, business IT solutions for government and commercial clients, tea production and distribution, real estate development, renewable power generation, health care projects, CSR projects, and more. </p>
      <p> Ploutus Holdings LLC is a global Infrastructure Development and Finance company. We work with clients worldwide in various industries and in diverse capacities to design, construct, and maintain their capital and infrastructure projects. Our work is focused on creating collaborative partnerships in the developing countries of Africa, the Caribbean, and Asia. <br><br> Our value proposition is to provide turnkey solutions to the governments that we work with, including sourcing the finances needed for the projects that we undertake.</p>  
    </div>
    <StaffList />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import StaffList from '@/components/StaffList.vue'

export default {
  components: {Header, StaffList},
  setup() {
    
  },
}
</script>

